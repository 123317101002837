import React from 'react';

import {
  Layout, Typography, Separator,
} from '../components';

const PolitiqueDeConfidentialite = () => (
  <Layout
    title="Politique de confidentialité - Docteur Amir Ghazanfari"
    index={false}
    follow={false}
    headerStyle="white"
  >
    <div className="px-4 py-12 bg-white">
      <div className="flex flex-col mx-auto space-y-12 max-w-screen-mdx">
        <Typography className="text-center uppercase tracking-[0.3em]" size="xlg" weight="bold">
          Politique de confidentialité
        </Typography>
        <div className="flex flex-col space-y-3">
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Qui sommes-nous ?
            </Typography>
            <Separator />
          </div>
          <Typography>
            L&apos;adresse de notre site Web est :
            {' '}
            <a className="underline text-link hover:text-primary" href="https://docteurghazanfari.com" target="_blank" rel="noreferrer">https://docteurghazanfari.com</a>
            .
          </Typography>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Utilisation des données personnelles collectées
            </Typography>
            <Separator />
          </div>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Commentaires
            </Typography>
            <Separator />
          </div>
          <Typography>
            Quand vous laissez un commentaire sur notre site web, les données inscrites dans le
            formulaire de commentaire, mais aussi votre adresse IP et l&apos;agent utilisateur de
            votre navigateur sont collectés pour nous aider à la détection des
            commentaires indésirables.
          </Typography>
          <div className="flex flex-col">
            <Typography>
              Une chaîne anonymisée créée à partir de votre adresse de messagerie
              (également appelée hash)
              peut être envoyée au service Gravatar
              pour vérifier si vous utilisez ce dernier. Les clauses
              de confidentialité du service Gravatar sont disponibles ici :
            </Typography>
            <Typography>
              https://automattic.com/privacy/. Après validation de votre commentaire,
              votre photo de profil sera visible publiquement à coté de votre commentaire.
            </Typography>
          </div>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Médias
            </Typography>
            <Separator />
          </div>
          <Typography>
            Si vous êtes un utilisateur ou une utilisatrice enregistré(e) et que
            vous téléversez des images sur le site web, nous vous conseillons
            d&apos;éviter de téléverser des images contenant des données EXIF de
            coordonnées GPS. Les visiteurs de votre site web peuvent télécharger
            et extraire des données de localisation depuis ces images.
          </Typography>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Formulaires de contact
            </Typography>
            <Separator />
          </div>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Cookies
            </Typography>
            <Separator />
          </div>
          <Typography>
            Si vous déposez un commentaire sur notre site,
            il vous sera proposé d&apos;enregistrer votre
            nom, adresse de messagerie et site web dans des
            cookies. C&apos;est uniquement pour votre
            confort afin de ne pas avoir à saisir ces informations si vous déposez un autre
            commentaire plus tard. Ces cookies expirent au bout d&apos;un an.
          </Typography>
          <Typography>
            Si vous vous rendez sur la page de connexion, un cookie temporaire sera
            créé afin de déterminer
            si votre navigateur accepte les cookies. Il ne contient pas de données personnelles
            et sera supprimé automatiquement à la fermeture de votre navigateur.
          </Typography>
          <Typography>
            Lorsque vous vous connecterez, nous mettrons en place un certain nombre de
            cookies pour enregistrer vos informations de connexion et vos préférences
            d&apos;écran. La durée de vie d&apos;un cookie de connexion est de deux jours, celle
            d&apos;un cookie d&apos;option d&apos;écran est d&apos;un an. Si vous cochez
            « Se souvenir de moi »,
            votre cookie de connexion sera conservé pendant deux semaines. Si vous
            vous déconnectez de votre compte, le cookie de connexion sera effacé.
          </Typography>
          <Typography>
            En modifiant ou en publiant une publication, un cookie supplémentaire sera
            enregistré dans votre navigateur. Ce cookie ne comprend aucune donnée personnelle.
            Il indique simplement l&apos;ID de la publication que vous venez de modifier.
            Il expire au bout d&apos;un jour.
          </Typography>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Contenu embarqué depuis d&apos;autres sites
            </Typography>
            <Separator />
          </div>
          <Typography>
            Les articles de ce site peuvent inclure des contenus intégrés
            (par exemple des vidéos, images, articles...). Le contenu intégré
            depuis d&apos;autres sites se comporte de la même manière que si le
            visiteur se rendait sur cet autre site.
          </Typography>
          <Typography>
            Ces sites web pourraient collecter des données sur vous, utiliser des cookies,
            embarquer des outils de suivis tiers, suivre vos interactions avec ces contenus
            embarqués si vous disposez d&apos;un compte connecté sur leur site web.
          </Typography>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Statistiques et mesures d&apos;audience
            </Typography>
            <Separator />
          </div>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Utilisation et transmission de vos données personnelles
            </Typography>
            <Separator />
          </div>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Durées de stockage de vos données
            </Typography>
            <Separator />
          </div>
          <Typography>
            Si vous laissez un commentaire, le commentaire et ses métadonnées sont
            conservés indéfiniment. Cela permet de reconnaître et approuver automatiquement
            les commentaires suivants au lieu de les laisser dans la file de modération.
          </Typography>
          <Typography>
            Pour les utilisateurs et utilisatrices qui s&apos;enregistrent sur notre site
            (si cela est possible),
            nous stockons également les données personnelles indiquées dans leur profil.
            Tous les utilisateurs et utilisatrices peuvent voir, modifier ou supprimer
            leurs informations personnelles à tout moment (à l&apos;exception de leur nom
            d&apos;utilisateur ou d&apos;utilisatrices). Les gestionnaires du site peuvent
            aussi voir et modifier ces informations.
          </Typography>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Les droits que vous avez sur vos données
            </Typography>
            <Separator />
          </div>
          <Typography>
            Si vous avez un compte ou si vous avez laissé des commentaires sur le site,
            vous pouvez demander à recevoir un fichier contenant toutes les données
            personnelles que nous possédons à votre sujet, incluant celles que vous
            nous avez fournies. Vous pouvez également demander la suppression des
            données personnelles vous concernant. Cela ne prend pas en compte les
            données stockées à des fins administratives, légales ou pour des raisons de sécurité.
          </Typography>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Transmission de vos données personnelles
            </Typography>
            <Separator />
          </div>
          <Typography>
            Les commentaires des visiteurs peuvent être vérifiés à l&apos;aide
            d&apos;un service automatisé de détection des commentaires indésirables.
          </Typography>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Informations de contact
            </Typography>
            <Separator />
          </div>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Informations supplémentaires
            </Typography>
            <Separator />
          </div>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Comment nous protégeons vos données
            </Typography>
            <Separator />
          </div>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Procédures mises en œuvre en cas de fuite de données
            </Typography>
            <Separator />
          </div>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Les services tiers qui nous transmettent des données
            </Typography>
            <Separator />
          </div>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Opérations de marketing automatisé et/ou de
              profilage réalisées à l&apos;aide des données personnelles
            </Typography>
            <Separator />
          </div>
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Affichage des informations liées aux secteurs soumis à des régulations spécifiques
            </Typography>
            <Separator />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default PolitiqueDeConfidentialite;
